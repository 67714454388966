import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import Tooltip from "../../ui/Tooltip";
import { HTMLAttributeAnchorTarget } from "react";

const PREFIX = "SidebarNavigationItem";

const classes = {
  itemActive: `${PREFIX}-itemActive`,
  listItem: `${PREFIX}-listItem`,
  listLinkItem: `${PREFIX}-listLinkItem`,
  listLinkLabel: `${PREFIX}-listLinkLabel`,
};

const StyledLi = styled("li")(({ theme }) => ({
  [`&.${classes.listItem}`]: {
    borderRadius: 15,
    listStyleType: "none",
    marginBottom: theme.spacing(2),
    position: "relative",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },

  [`& .${classes.listLinkItem}`]: {
    "&:hover": {
      color: theme.palette.bloom.main,
      transition: "color 0.25s",
    },
    alignItems: "center",
    borderRadius: 5,
    color: theme.palette.black.main,
    display: "flex",
    padding: theme.spacing(3, 4),

    textDecoration: "none",

    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
      display: "flex",
      flexFlow: "column nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },

  [`& .${classes.listLinkLabel}`]: {
    fontSize: 16,
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0, 2),

    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1, 0, 0, 0),
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.itemActive}`]: {
    "& .icon": {
      color: theme.palette.bloom.dark,
    },
    color: theme.palette.bloom.dark,

    position: "relative",
  },
}));

interface ISidebarNavigationItem {
  exact?: boolean;
  href: string;
  icon: any;
  id: string;
  isExternalLink?: boolean;
  label: string;
  target?: HTMLAttributeAnchorTarget;
}

const SidebarNavigationItem = ({
  label,
  href,
  isExternalLink = false,
  icon,
  id,
  target,
}: ISidebarNavigationItem) => {
  const tablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("xs", "lg"),
  );

  return (
    <StyledLi className={classes.listItem}>
      {isExternalLink && (
        <a
          className={classes.listLinkItem}
          href={href}
          rel="noreferrer"
          target="_blank"
        >
          <span className={"icon"}>{icon}</span>
          {!tablet && <LinkContent label={label} />}
        </a>
      )}
      {!isExternalLink && (
        <NavLink
          className={({ isActive }) =>
            clsx({
              [classes.itemActive]: isActive && target !== "_blank",
              [classes.listLinkItem]: true,
            })
          }
          id={id}
          target={target}
          to={`${href}`}
        >
          <Tooltip text={label}>
            <span className={"icon"}>{icon}</span>
          </Tooltip>
          {!tablet && <LinkContent label={label} />}
        </NavLink>
      )}
    </StyledLi>
  );
};

interface ILinkContent {
  label: string;
}

function LinkContent({ label }: ILinkContent) {
  return (
    <Typography className={classes.listLinkLabel} variant="subtitle1">
      {label}
    </Typography>
  );
}

export default SidebarNavigationItem;
