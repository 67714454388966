import { Box, Typography, useTheme, Link } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery, useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";
import Select from "react-select";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import Footer from "./Common/Footer";
import useTrackPageView from "../../providers/matomo/useTrackPageView";

const mutualitiesQuery = graphql(`
  query Mutualities {
    currentMatchedHuman {
      id
      mutuality {
        id
      }
      mutualityOptions {
        otherMutualities {
          descriptionTranslationKey
          id
          imgUrl
          linkUrlTranslationKey
          preferred
          title
        }
        regionMutualities {
          descriptionTranslationKey
          id
          imgUrl
          linkUrlTranslationKey
          preferred
          title
        }
      }
      noMutuality
      organization {
        consultsPerHuman
        id
        name {
          ...useSelectTranslationStringInput
        }
        videoEnabled
      }
    }
  }
`);

const submitMutualityStepMutation = graphql(`
  mutation SubmitMutualityStep($mutualityId: String) {
    submitMutualityStep(mutualityId: $mutualityId) {
      matchedHuman {
        id
        mutuality {
          id
        }
        noMutuality
      }
      matchingToken
      nextStep
    }
  }
`);

interface MutualityStepProps {
  stepBack: () => void;
  stepForward: (nextStep: string, token: string) => void;
}

const MutualityStep = ({ stepBack, stepForward }: MutualityStepProps) => {
  useTrackPageView("Match > Mutuality");

  const { t } = useTranslation();
  const theme = useTheme();

  const { data: mutualitiesData } = useQuery(mutualitiesQuery, {
    onCompleted: (data) => {
      const matchedHuman = data.currentMatchedHuman;

      if (matchedHuman?.noMutuality) {
        setChoice(null);
      } else {
        setChoice(matchedHuman?.mutuality?.id);
      }
    },
  });

  const [choice, setChoice] = useState<string | null | undefined>(undefined);

  const otherChoice = useMemo(() => {
    const otherMutuality =
      mutualitiesData?.currentMatchedHuman?.mutualityOptions.otherMutualities.find(
        (mutuality) => mutuality.id === choice,
      );

    const result = otherMutuality
      ? {
          label: otherMutuality.title,
          value: otherMutuality.id,
        }
      : choice === undefined
      ? undefined
      : null;

    return result;
  }, [
    choice,
    mutualitiesData?.currentMatchedHuman?.mutualityOptions.otherMutualities,
  ]);

  const [submitMutualityStep] = useMutation(submitMutualityStepMutation);
  const selectTranslation = useSelectTranslation();

  const preferredMutualities =
    mutualitiesData?.currentMatchedHuman?.mutualityOptions.regionMutualities.filter(
      (e) => e.preferred,
    );

  const regularMutualities =
    mutualitiesData?.currentMatchedHuman?.mutualityOptions.regionMutualities.filter(
      (e) => !e.preferred,
    );

  const organization = mutualitiesData?.currentMatchedHuman?.organization;

  return (
    <>
      <StepHeader
        imageUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D19-idea%2C+Size%3DSmall.png"
        subtitle={
          organization &&
          organization.videoEnabled &&
          (organization.consultsPerHuman ?? 0) > 0 // coaching not taken into account because not B2C available
            ? t("match:mutualityStep.subTitle", {
                orgName: selectTranslation(organization.name),
              })
            : t("match:mutualityStep.subTitle_B2C")
        }
        title={t("match:mutualityStep.title")}
      />
      <StepMainContent
        enableNext={choice !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitMutualityStep({
            variables: {
              mutualityId: choice,
            },
          });

          submitData &&
            stepForward(
              submitData.submitMutualityStep.nextStep,
              submitData.submitMutualityStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            maxWidth: "1000px",
          }}
        >
          {preferredMutualities && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "35px 62px",
                justifyContent: "center",
                paddingY: "50px",
              }}
            >
              {preferredMutualities.map((mutuality) => (
                <BloomieCard
                  bodyChildren={
                    <Box>
                      <Typography
                        className="bodyText"
                        sx={{
                          "&:hover": {
                            color:
                              choice === mutuality.id
                                ? undefined
                                : theme.palette.black.main,
                          },
                          color:
                            choice === mutuality.id
                              ? theme.palette.black.main
                              : theme.palette.black.light,
                          fontSize: "12px",
                          fontWeight: 400,
                          transition: "color 0.3s ease-out",
                        }}
                      >
                        {t("match:mutualities.preferred.descAddon")}
                      </Typography>
                    </Box>
                  }
                  bodyText={
                    mutuality.descriptionTranslationKey
                      ? t(
                          mutuality.descriptionTranslationKey,
                          organization
                            ? {
                                orgName: selectTranslation(organization.name),
                              }
                            : { context: "B2C" },
                        )
                      : ""
                  }
                  checked={choice === mutuality.id}
                  footerChildren={
                    <Link
                      href={t(mutuality.linkUrlTranslationKey)}
                      style={{ fontSize: "12px" }}
                      target="_blank"
                      variant="accessibleLink"
                    >
                      {t("match:bloomieCard.moreInfo")}
                    </Link>
                  }
                  imgUrl={mutuality.imgUrl}
                  key={mutuality.id}
                  logoImg
                  onClick={() => {
                    setChoice(mutuality.id);
                  }}
                  size="lg"
                  sx={{ justifyContent: "start" }}
                  type="outlined"
                />
              ))}
            </Box>
          )}
          {regularMutualities && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "35px 62px",
                justifyContent: "center",
              }}
            >
              {regularMutualities.map((mutuality) => (
                <BloomieCard
                  checked={choice === mutuality.id}
                  footerChildren={
                    <Link
                      href={t(mutuality.linkUrlTranslationKey)}
                      style={{ fontSize: "12px", paddingTop: "10px" }}
                      target="_blank"
                      variant="accessibleLink"
                    >
                      {t("match:bloomieCard.moreInfo")}
                    </Link>
                  }
                  imgUrl={mutuality.imgUrl}
                  key={mutuality.id}
                  logoImg
                  onClick={() => {
                    setChoice(mutuality.id);
                  }}
                  testId={mutuality.title}
                  type={"outlined"}
                />
              ))}
              <BloomieCard
                checked={choice === null}
                footerChildren={
                  <Typography
                    sx={{
                      color: theme.palette.black.light,
                      fontSize: "12px",
                      paddingTop: "10px",
                    }}
                  >
                    {t("match:mutualityStep.dontKnow")}
                  </Typography>
                }
                imgUrl={
                  "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D63-dont-know%2C+Size%3DSmall.png"
                }
                key={0}
                onClick={() => {
                  setChoice(null);
                }}
                type={"outlined"}
              />
            </Box>
          )}
          {mutualitiesData?.currentMatchedHuman?.mutualityOptions
            .otherMutualities.length &&
            mutualitiesData.currentMatchedHuman.mutualityOptions
              .otherMutualities.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  maxWidth: "500px",
                  padding: "50px 0px 20px 0px",
                  width: "100%",
                }}
              >
                <Typography variant="h2">
                  {t("match:mutualities.other")}
                </Typography>
                <Select
                  onChange={(e) => {
                    setChoice(e?.value);
                  }}
                  options={mutualitiesData.currentMatchedHuman.mutualityOptions.otherMutualities
                    .map((mutuality) => {
                      return {
                        label: mutuality.title,
                        value: mutuality.id,
                      };
                    })
                    .sort((a, b) => {
                      if (a.label < b.label) return -1;
                      else if (a.label === b.label) return 0;
                      else return 1;
                    })}
                  placeholder={t("match:mutualities.other.select.placeholder")}
                  styles={{
                    control: () => ({
                      border: "3px solid",
                      borderRadius: 100,
                      display: "flex",
                      fontSize: "16px",
                      padding: "4px 8px",
                      textAlign: "left",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    menu: (defaultValue) => ({
                      ...defaultValue,
                      border: "1px solid",
                    }),
                  }}
                  value={otherChoice}
                />
              </Box>
            )}
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default MutualityStep;
