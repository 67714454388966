import { Box } from "@mui/material";
import React, { useState } from "react";
import { graphql } from "../../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";
import Footer from "./Common/Footer";
import StepHeader from "./Common/StepHeader";
import useTrackPageView from "../../providers/matomo/useTrackPageView";

const countriesQuery = graphql(`
  query Countries {
    countries {
      descriptionTranslationKey
      id
      imgUrl
      regions {
        descriptionTranslationKey
        id
        imgUrl
        titleTranslationKey
      }
      titleTranslationKey
    }
  }
`);

const matchedHumanQuery = graphql(`
  query LocationStepMatchedHuman {
    currentMatchedHuman {
      country {
        id
      }
      id
      noLocation
      region {
        id
      }
    }
  }
`);

type Choice = {
  countryId: string;
  regionId?: string;
};

const submitLocationStepMutation = graphql(`
  mutation SubmitLocationStep($input: SubmitLocationStepInput!) {
    submitLocationStep(input: $input) {
      matchedHuman {
        country {
          id
        }
        id
        mutualityOptions {
          otherMutualities {
            id
          }
          regionMutualities {
            id
          }
        }
        noLocation
        region {
          id
        }
      }
      matchingToken
      nextStep
    }
  }
`);

interface LocationStepProps {
  stepBack: () => void;
  stepForward: (nextStep: string, token: string) => void;
}

const LocationStep = ({ stepBack, stepForward }: LocationStepProps) => {
  useTrackPageView("Match > Location");

  const { t } = useTranslation();

  const { data: countriesData } = useQuery(countriesQuery);

  useQuery(matchedHumanQuery, {
    onCompleted: (data) => {
      const matchedHuman = data.currentMatchedHuman;

      if (matchedHuman?.noLocation) {
        setChoice(null);
      } else if (matchedHuman?.country?.id) {
        setChoice({
          countryId: matchedHuman.country.id,
          regionId: matchedHuman.region?.id,
        });
      }
    },
  });

  const [submitLocationStep] = useMutation(submitLocationStepMutation);
  const [choice, setChoice] = useState<Choice | null | undefined>();

  return (
    <>
      <StepHeader
        imageUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D56-location-marker%2C+Size%3DSmall.png"
        subtitle={t("match:locationStep.subTitle")}
        title={t("match:locationStep.title")}
      />
      <StepMainContent
        enableNext={choice !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitLocationStep({
            variables: {
              input: {
                countryId: choice?.countryId,
                regionId: choice?.regionId,
              },
            },
          });

          submitData &&
            stepForward(
              submitData.submitLocationStep.nextStep,
              submitData.submitLocationStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
            gap: "35px 62px",
            justifyContent: "center",
            maxWidth: "1000px",
          }}
        >
          {countriesData?.countries.map((country) =>
            country.regions && country.regions[0] ? (
              country.regions.map((region) => (
                <BloomieCard
                  bodyText={t(region.descriptionTranslationKey)}
                  checked={choice?.regionId === region.id}
                  imgUrl={region.imgUrl}
                  key={region.id}
                  onClick={() => {
                    setChoice({ countryId: country.id, regionId: region.id });
                  }}
                  title={t(region.titleTranslationKey)}
                  type="outlined"
                />
              ))
            ) : (
              <BloomieCard
                bodyText={t(country.descriptionTranslationKey)}
                checked={choice?.countryId === country.id}
                imgUrl={country.imgUrl}
                key={country.id}
                onClick={() => {
                  setChoice({ countryId: country.id });
                }}
                title={t(country.titleTranslationKey)}
                type="outlined"
              />
            ),
          )}
          <BloomieCard
            bodyText={t("match:regions.else.desc")}
            checked={choice === null}
            imgUrl={
              "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D61-other-place%2C+Size%3DSmall.png"
            }
            key={0}
            onClick={() => {
              setChoice(null);
            }}
            title={t("match:regions.else")}
            type="outlined"
          />
        </Box>
      </StepMainContent>
      <Footer atomiumText />
    </>
  );
};

export default LocationStep;
