import { Avatar, Box, Typography } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery } from "@apollo/client";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import Footer from "./Common/Footer";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { getDateFnsLocale } from "../../language/languagesUtil";
import Button from "../../ui/buttons/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes/routeNames";
import ConsultationRules from "./ChooseProf/ConsultationRules";
import useTrackPageView from "../../providers/matomo/useTrackPageView";

const matchConsultationQuery = graphql(`
  query MatchConsultation {
    currentMatchedHuman {
      id
      matchConsultation {
        id
        professional {
          avatar {
            url
          }
          id
          user {
            firstName
            id
            lastName
          }
        }
        scheduledFrom
        type
      }
      subtopic {
        id
        titleTranslationKey
      }
    }
  }
`);

interface BookedProfCardProps {
  dateString?: string;
  user: { firstName?: string; imgUrl?: string; lastName?: string };
}

const BookedProfCard = ({ dateString, user }: BookedProfCardProps) => {
  const { i18n } = useTranslation();

  const { firstName, imgUrl, lastName } = user;
  const dateFnsLocale = getDateFnsLocale(i18n.language);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down(400)]: {
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        },
        display: "flex",
        gap: "25px",
      })}
    >
      <Avatar
        alt={firstName + " " + lastName}
        src={imgUrl}
        sx={(theme) => ({
          border: "1px",
          borderColor: theme.palette.bloom.dark,
          height: "200px",
          width: "200px",
          [theme.breakpoints.down(750)]: {
            height: "120px",
            width: "120px",
          },
          [theme.breakpoints.down(500)]: {
            height: "100px",
            width: "100px",
          },
        })}
      />
      <Box
        sx={(theme) => ({
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          [theme.breakpoints.up(400)]: {
            paddingLeft: "26px",
          },
        })}
      >
        <Typography
          sx={(theme) => ({
            [theme.breakpoints.down(750)]: { fontSize: "24px" },
          })}
          variant="h1"
        >
          {user.firstName + " " + user.lastName}
        </Typography>
        {dateString && (
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down(750)]: { fontSize: "18px" },
            })}
            variant="h2"
          >
            {format(dateString, "PPPPp", { locale: dateFnsLocale })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const EndMatchingStep = () => {
  useTrackPageView("Match > End");

  const { data } = useQuery(matchConsultationQuery);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <StepHeader
        imageUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D75-coaching%2C+Size%3DSmall.png"
        subtitle={
          data?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
          t(data.currentMatchedHuman.subtopic.titleTranslationKey)
        }
        title={t(
          data?.currentMatchedHuman?.matchConsultation?.type === "APPOINTMENT"
            ? "match:treatmentStep.treatments.therapy.title"
            : "match:treatmentStep.treatments.coaching.title",
        )}
      />
      <StepMainContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              height: "100%",
              paddingBottom: "45px",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.bloom.main,
                fontSize: "45px",
                [theme.breakpoints.down(750)]: { fontSize: "24px" },
              })}
              variant="h1"
            >
              {data?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
                t(data.currentMatchedHuman.subtopic.titleTranslationKey)}
            </Typography>
            <Typography
              sx={(theme) => ({
                [theme.breakpoints.down(750)]: { fontSize: "20px" },
              })}
              variant="h2"
            >
              {t("match:endStep.header.subTitle")}
            </Typography>
          </Box>
          {data?.currentMatchedHuman?.matchConsultation && (
            <BookedProfCard
              dateString={
                data.currentMatchedHuman.matchConsultation.scheduledFrom
              }
              user={{
                firstName:
                  data.currentMatchedHuman.matchConsultation.professional?.user
                    .firstName || undefined,
                imgUrl:
                  data.currentMatchedHuman.matchConsultation.professional
                    ?.avatar?.url || undefined,
                lastName:
                  data.currentMatchedHuman.matchConsultation.professional?.user
                    .lastName || undefined,
              }}
            />
          )}
          <Box
            sx={(theme) => ({
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
              maxWidth: "700px",
              [theme.breakpoints.down(650)]: {
                padding: "16px 0px 8px 0px",
              },
              padding: "75px 0px 35px 0px",
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.red.dark,
              })}
              textAlign="center"
              variant="body1"
            >
              {t("match:endstep.mainContent.acceptInfo")}
            </Typography>
            <Button
              endIcon={<ArrowForwardIosIcon style={{ fontSize: "18px" }} />}
              fullyColored
              label={t("match:endStep.mainContent.dashboardButton")}
              onClick={() => {
                navigate(RouteNames.Home.Consultations.path);
              }}
              style={{
                fontSize: "18px",
              }}
            />
          </Box>
          <ConsultationRules
            name={
              data?.currentMatchedHuman?.matchConsultation?.professional?.user
                .firstName ?? undefined
            }
          />
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default EndMatchingStep;
