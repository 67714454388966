import {
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ConsoleLogger, LogLevel } from "amazon-chime-sdk-js";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CrispChat from "../utils/crisp-chat/CrispChat";
import AdminProfessionalPageWithState from "./pages/admin/professionals/AdminProfessionalsPageWithState";
import ConfirmConsultationPage from "./pages/confirm-consultation/ConfirmConsultationPage";
import OrganizationLandingPage from "./pages/organizations/landing-pages";
import GgtLandingPage from "./pages/organizations/landing-pages/GgtLandingPage";
import VerifyOrganizationPage from "./pages/organizations/landing-pages/VerifyOrganizationPage";
import VideoLandingPage from "./pages/organizations/landing-pages/VideoLandingPage";
import AcceptInvite from "./pages/professional-clients/AcceptInvite";
import WelcomePage from "./pages/welcome/WelcomePage";
import AuthRoute from "./routes/AuthRoute";
import HumanHomeRoutes from "./routes/HumanHomeRoutes";
import HumanRegistrationRoutes from "./routes/HumanRegistrationRoutes";
import HumanVideoCallRoutes from "./routes/HumanVideoCallRoutes";
import PasswordRoutes from "./routes/PasswordRoutes";
import PaymentRoutes from "./routes/PaymentRoutes";
import ProfessionalCallRoutes from "./routes/ProfessionalCallRoutes";
import ProfessionalHomeRoutes from "./routes/ProfessionalHomeRoutes";
import ProfessionalOnboardingRoutes from "./routes/ProfessionalOnboardingRoutes";
import ProfessionalRegistrationRoutes from "./routes/ProfessionalRegistrationRoutes";
import QualityQuestionRoutes from "./routes/QualityQuestionRoutes";
import AdminGuard from "./routes/guards/AdminGuard";
import HumanGuard from "./routes/guards/HumanGuard";
import ProfessionalGuard from "./routes/guards/ProfessionalGuard";
import { RouteNames } from "./routes/routeNames";
import { defaultCallValues } from "./ui/call/context/CallContext";
import CallStateProvider from "./ui/call/context/CallStateProvider";
import SelfCareLandingPage from "./pages/organizations/landing-pages/SelfCareLandingPage";
import LoginRoutes from "./routes/LoginRoutes";
import Match from "./pages/match/Match";
import RedirectFromOldMatching from "./pages/match/RedirectFromOldMatching";

function BaseRouting({ children }) {
  return (
    <Routes>
      <Route element={children} path="/*" />
    </Routes>
  );
}

export default function AppRouter() {
  const logger = new ConsoleLogger("SDK", LogLevel.INFO);

  const meetingConfig = {
    logger,
  };

  return (
    <BrowserRouter>
      <BaseRouting>
        <Routes>
          <Route element={<RedirectFromOldMatching />} path={`matching/*`} />
          <Route element={<Navigate to="/employee" />} path="/partner/*" />
        </Routes>
        <>
          <CrispChat />
          <Routes>
            <Route
              element={<VerifyOrganizationPage />}
              path={`employee/verify`}
            />
            <Route element={<OrganizationLandingPage />} path={`/employee`} />
            <Route
              element={<Navigate replace to="/employee" />}
              path={`${RouteNames.NewB2BLandingPages.name}/:slug`}
            />
            <Route
              element={<GgtLandingPage />}
              path={`/employee/${RouteNames.NewB2BLandingPages.ggt.name}`}
            />
            <Route
              element={<SelfCareLandingPage />}
              path={`/employee/${RouteNames.NewB2BLandingPages.selfCare.name}`}
            />
            <Route
              element={<GgtLandingPage />}
              path={`${RouteNames.NewB2BLandingPages.name}/:slug/${RouteNames.NewB2BLandingPages.ggt.name}`}
            />
            <Route element={<VideoLandingPage />} path={`/employee/video`} />
            <Route
              element={<VideoLandingPage />}
              path={`${RouteNames.NewB2BLandingPages.name}/:slug/video`}
            />
          </Routes>
        </>
        <Routes>
          <Route element={<Match />} path={`Match/*`} />
          <Route
            element={<ProfessionalRegistrationRoutes />}
            path={`${RouteNames.Register.Professional.path}/*`}
          />
          <Route
            element={<HumanRegistrationRoutes />}
            path={`${RouteNames.Register.Human.path}/*`}
          />
          <Route
            element={<PasswordRoutes />}
            path={`${RouteNames.Password.path}/*`}
          />
          <Route
            element={<LoginRoutes />}
            path={`${RouteNames.Login.path}/*`}
          />
          <Route
            element={<ConfirmConsultationPage />}
            path={`${RouteNames.Home.Consultations.ShowConsultation.Confirm.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <ProfessionalHomeRoutes />
                <HumanHomeRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.Home.path}/*`}
          />
          <Route
            element={<AcceptInvite />}
            path={`${RouteNames.Invite.Human.path}/*`}
          />
          {/* For these routes, authentication is required*/}
          {/*TODO: we can add another sentry error boundry here, to collect extra information from users*/}
          <Route
            element={
              <AuthRoute>
                <AdminGuard>
                  <AdminProfessionalPageWithState />
                </AdminGuard>
              </AuthRoute>
            }
            path={`${RouteNames.Admin.Dashboard.path}/*`}
          />
          <Route
            element={<ProfessionalOnboardingRoutes />}
            path={`${RouteNames.Onboarding.Professional.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                {/* This is needed for the amazon-chime-sdk-component-library-react library */}
                <ThemeProvider theme={lightTheme}>
                  <MeetingProvider {...meetingConfig}>
                    <CallStateProvider {...defaultCallValues}>
                      <HumanGuard>
                        <HumanVideoCallRoutes />
                      </HumanGuard>
                      <ProfessionalGuard>
                        <ProfessionalCallRoutes />
                      </ProfessionalGuard>
                    </CallStateProvider>
                  </MeetingProvider>
                </ThemeProvider>
              </AuthRoute>
            }
            path={`${RouteNames.Call.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <PaymentRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.Payment.Consultation.Pay.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <QualityQuestionRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.QualityQuestions.path}/*`}
          />
          {/** Catch all */}
          <Route element={<WelcomePage />} path="/" />
        </Routes>
      </BaseRouting>
    </BrowserRouter>
  );
}
