import List from "@mui/material/List";

import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import LeftSection from "../../layout/LeftSection";
import UnavailableSection from "../../layout/UnavailableSection";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import ButtonWithStatus from "../../ui/buttons/ButtonWithStatus";
import Avatar from "../../ui/Avatar";
import { UserRoles } from "../../../data-model/types/User";

const colleagueFragment = graphql(`
  fragment Colleague on Professional {
    id
    primarySubtopics {
      id
      titleTranslationKey
    }
    therapyTypes
    user {
      ...Avatar
      firstName
      id
      lastName
    }
  }
`);

interface ColleaguesListProps {
  colleagues: FragmentType<typeof colleagueFragment>[];
  handleColleagueClick: (id: number) => void;
  selectedColleagueId?: number;
}

const ColleaguesList = ({
  colleagues,
  selectedColleagueId,
  handleColleagueClick,
}: ColleaguesListProps) => {
  const { t } = useTranslation();

  return (
    <LeftSection pageTitle={t("common:colleagues")} short={true}>
      {colleagues && colleagues.length > 0 ? (
        <List
          sx={{
            display: "flex",
            flexFlow: "column nowrap",
            margin: 0,
            padding: 0,
            width: "100%",
          }}
        >
          {colleagues.map((colleagueData) => {
            const colleague = getFragmentData(colleagueFragment, colleagueData);
            const topics = colleague.primarySubtopics.map((topic) =>
              t(topic.titleTranslationKey),
            );

            const therapies = colleague.therapyTypes
              ? JSON.parse(colleague.therapyTypes).map((translationKey) =>
                  translationKey
                    ? t(`glossary:therapytype.${translationKey}`)
                    : undefined,
                )
              : [];

            const description = [...topics, ...therapies]
              .filter(Boolean)
              .join(", ");

            return (
              <ButtonWithStatus
                isOpen={
                  selectedColleagueId !== undefined &&
                  selectedColleagueId === colleague.id
                }
                key={colleague.id}
                onClick={() => {
                  handleColleagueClick(colleague.id);
                }}
                requestedBy={"PROFESSIONAL"}
                roleType={UserRoles.PROFESSIONAL}
                status={undefined}
              >
                <Grid container>
                  <Grid item md={1} sm={1} xs={1}>
                    <Avatar size={30} user={colleague.user} />
                  </Grid>
                  <Grid
                    item
                    md={11}
                    sm={11}
                    sx={(theme) => ({
                      paddingLeft: "15px",
                      [theme.breakpoints.down(340)]: {
                        paddingLeft: "20px",
                      },
                    })}
                    xs={11}
                  >
                    <Typography
                      align="left"
                      noWrap
                      sx={(theme) => ({ fontSize: theme.typography.sizes.xl })}
                    >
                      {`${colleague.user.firstName} ${colleague.user.lastName}`}
                    </Typography>
                    {description.length > 0 && (
                      <Typography
                        align="left"
                        sx={(theme) => ({
                          color: theme.palette.gray.dark,
                          fontSize: theme.typography.sizes.normal,
                        })}
                      >
                        {description}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ButtonWithStatus>
            );
          })}
        </List>
      ) : (
        <UnavailableSection text={t("common:status.not_found.colleagues")} />
      )}
    </LeftSection>
  );
};

export default ColleaguesList;
