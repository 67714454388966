import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { GenderSelect } from "../../../ui/form/EmotionSelect";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import GenderDataType from "../../../../data-model/types/profile/Gender";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

export default function PsyGenderStep() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
  ]);

  const { next, previous, step, totalSteps, formState } =
    useContext<ProfessionalOnboardingContextProps>(
      ProfessionalOnboardingContext,
    );

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("professional:onboarding.gender.description")}
        disable={formState.hasError("gender")}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.gender.title",
          "Jouw geslacht",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <GenderSelect
          emoticons={GenderDataType.getSelectOptions(translate)}
          {...formState.getInputProps(GenderDataType.getPath())}
          last
          noWrap
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
