/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";
import { IProfessionalProfileContext } from "./professionalProfile";

const defaultProfessionalProfileValues: IProfessionalProfileContext = {
  formState: {},
  loading: false,
  onSave: () => new Promise(() => {}),
  professional: undefined,
  updateBillingInformation: () => new Promise(() => {}),
  updateDescriptions: () => new Promise(() => {}),
  updatePractice: () => new Promise(() => {}),
  updatePreferredLanguage: () => new Promise(() => {}),
  updateTimezone: () => new Promise(() => {}),
};

const ProfessionalProfileContext = createContext<IProfessionalProfileContext>(
  defaultProfessionalProfileValues,
);

export default ProfessionalProfileContext;
