/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useLazyFetchConsultationByUuid from "../../api/consultations/hooks/useLazyFetchConsultationByUuid";
import { DateFormat } from "../../data-model/Date";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import DeprecatedAvatar from "../ui/DeprecatedAvatar";
import FAQAccordion from "../ui/FAQAccordion";
import FAQAccordionItem, { IFAQData } from "../ui/FAQAccordionItem";
import SanitizedText from "../ui/text/SanitizedText";
import Professional from "../../data-model/types/professional/Professional";
import { getMomentLocale } from "../language/languagesUtil";
import { ConsultationTypes } from "../../data-model/types/consultation/Consultation";
import AffiliatedWithInsuranceCompany from "./components/AffiliatedWithInsuranceCompany";
import { IPaymentContext } from "./context/payment";
import PaymentContext from "./context/PaymentContext";
import { graphql } from "../../api/__generated__";
import { useQuery } from "@apollo/client";

const PREFIX = "PaymentPage";

const classes = {
  consultationDetailsContainer: `${PREFIX}-consultationDetailsContainer`,
  consultationDetailsTextContainer: `${PREFIX}-consultationDetailsTextContainer`,
  faq: `${PREFIX}-faq`,
  leftSide: `${PREFIX}-leftSide`,
  rightSide: `${PREFIX}-rightSide`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  [`& .${classes.rightSide}`]: { flex: 1, margin: theme.spacing(5) },
  [`& .${classes.leftSide}`]: { flex: 1, margin: theme.spacing(5) },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.consultationDetailsContainer}`]: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(6),
  },

  [`& .${classes.consultationDetailsTextContainer}`]: {
    marginLeft: 20,
  },

  [`& .${classes.faq}`]: {
    marginBottom: theme.spacing(2),
  },
}));

const humanQuery = graphql(`
  query PaymentPageHuman {
    currentUser {
      human {
        ...AffiliatedMutualityHuman
        id
      }
      id
    }
  }
`);

export default function PaymentPage() {
  const { pay, formState } = useContext<IPaymentContext>(PaymentContext);
  const { t, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
    BloomUpNamespacesEnum.Payments,
    BloomUpNamespacesEnum.Common,
  ]);

  const { fetchConsultation, consultation } = useLazyFetchConsultationByUuid();
  const { data: humanData } = useQuery(humanQuery);

  const { uuid } = useParams();

  useEffect(() => {
    if (uuid) fetchConsultation(uuid);
  }, [uuid]);

  const eligibleForReimbursement =
    consultation?.getConsultationType() === ConsultationTypes.APPOINTMENT;

  const questions: Array<IFAQData> = [
    {
      answer: t("payments:payment.page.faq.pay.upfront.answer"),
      question: t("payments:payment.page.faq.pay.upfront.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.cancellation.answer"),
      question: t("payments:payment.page.faq.pay.cancellation.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.paymentdata.answer"),
      question: t("payments:payment.page.faq.pay.paymentdata.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.proof.answer"),
      question: t("payments:payment.page.faq.pay.proof.question"),
    },
  ];

  if (eligibleForReimbursement) {
    questions.push({
      answer: t("payments:payment.page.faq.othermutualities.answer.helan"),
      question: t("payments:payment.page.faq.othermutualities.question"),
    });
  }

  const professional: Professional | null = useMemo(() => {
    if (consultation) {
      return consultation.getProfessional();
    }

    return null;
  }, [consultation]);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullContent={true}
        fullCopyright
        headline={t("payments:payment")}
        showLanguageSelector={false}
      >
        <StyledBox className={classes.root}>
          <Box className={classes.leftSide} mb={3}>
            {humanData?.currentUser?.human && (
              <AffiliatedWithInsuranceCompany
                eligibleForReimbursement={eligibleForReimbursement}
                formState={formState}
                human={humanData.currentUser.human}
                pay={pay}
                price={consultation?.getCashTransaction()?.getAmount()}
                uuid={uuid}
              />
            )}
          </Box>
          <Box className={classes.rightSide}>
            <Box>
              <Typography className={classes.title} variant="h2">
                {t("common:consultation_type.APPOINTMENT")}
              </Typography>

              <Box className={classes.consultationDetailsContainer}>
                {professional && (
                  <DeprecatedAvatar size={40} user={professional} />
                )}
                <Box className={classes.consultationDetailsTextContainer}>
                  {professional && (
                    <Typography>{professional.getFullName()}</Typography>
                  )}
                  <Typography>
                    {consultation
                      ?.getStartDate(
                        DateFormat.ONLY_DATE_SHORT_MONTH,
                        getMomentLocale(i18n.language),
                      )
                      .toString() || t("common:-")}
                    &emsp;
                    <SanitizedText
                      input={t("payments:payment.page.time.format", {
                        defaultValue: "{{startTime}} &rarr; {{endTime}}",
                        endTime: consultation?.getEndDate(DateFormat.ONLY_TIME),
                        startTime: consultation?.getStartDate(
                          DateFormat.ONLY_TIME,
                        ),
                      }).toString()}
                    />
                  </Typography>
                </Box>
              </Box>
              <Typography className={classes.title} variant="h2">
                {t("payments:payment.page.faq.title")}
              </Typography>
              <Box className={classes.faq}>
                <FAQAccordion>
                  {questions.map((question) => {
                    return (
                      <FAQAccordionItem
                        answer={question.answer}
                        key={question.question}
                        question={question.question}
                      />
                    );
                  })}
                </FAQAccordion>
              </Box>
              <Box>
                <Typography className={classes.title} variant="h2">
                  {t("payments:payment.page.terms.title")}
                </Typography>
                <Typography>
                  <SanitizedText
                    input={t("payments:payment.page.terms.text")}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
