import { useTranslation } from "react-i18next";
import { RouteNames } from "../../routes/routeNames";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import { format } from "date-fns";
import { useQuery } from "@apollo/client";

const consultationFragment = graphql(`
  fragment ReimbursementInfoConsultation on Consultation {
    cashTransaction {
      id
      status
    }
    expectedReimbursementDate
    human {
      id
      mutuality {
        id
        title
      }
    }
    id
    scheduledFrom
    uuid
  }
`);

const mutualitiesQuery = graphql(`
  query ReimbursementInfoMutualities {
    mutualities(filter: { preferred: true }) {
      id
      preferred
      title
    }
  }
`);

export default function ReimbursementInfo({
  consultation,
}: {
  consultation: FragmentType<typeof consultationFragment>;
}) {
  const { t } = useTranslation();

  const { data } = useQuery(mutualitiesQuery);

  const consultationData = getFragmentData(consultationFragment, consultation);
  const cashTransaction = consultationData.cashTransaction;
  const expectedReimbursementDate = consultationData.expectedReimbursementDate;
  const mutualityTitle = consultationData.human?.mutuality?.title;

  const reimbursementPageLink =
    `${RouteNames.Payment.Consultation.Pay.Reimbursement.path}`.replace(
      ":uuid",
      consultationData.uuid,
    );

  if (!cashTransaction) return null;

  if (["new", "open"].includes(cashTransaction.status)) {
    return (
      <ValueWithLabel label={t(`payments:reimbursement.status.text`)}>
        {t(`payments:reimbursement.not.requested_with_helan`, {
          mutualityTitle,
        })}
      </ValueWithLabel>
    );
  } else if (expectedReimbursementDate) {
    return (
      <ValueWithLabel label={t(`payments:reimbursement.status.text`)}>
        {t(`payments:reimbursement.expected`, {
          expectedReimbursementDate: format(
            new Date(expectedReimbursementDate),
            "dd/MM/yyyy",
          ),
          mutualityTitle,
        })}
      </ValueWithLabel>
    );
  } else if (!mutualityTitle) {
    return (
      <ValueWithLabel label={t(`payments:reimbursement.status.text`)}>
        {data?.mutualities.map((mutuality) => {
          return mutuality.title !== "Helan" ||
            new Date(consultationData.scheduledFrom) >
              new Date("2025-01-01 00:00:00") ? (
            <a
              href={`${reimbursementPageLink}?linkToMutuality=${mutuality.id}`}
              style={{ marginRight: "10px" }}
            >
              {t("payments:reimbursement.request_with_helan", {
                mutualityTitle: mutuality.title,
              })}
            </a>
          ) : null;
        })}
      </ValueWithLabel>
    );
  } else if (
    mutualityTitle === "CM" ||
    (mutualityTitle === "Helan" &&
      new Date(consultationData.scheduledFrom) >
        new Date("2025-01-01 00:00:00"))
  ) {
    return (
      <ValueWithLabel label={t(`payments:reimbursement.status.text`)}>
        <a href={reimbursementPageLink}>
          {t("payments:reimbursement.request_with_helan", {
            mutualityTitle,
          })}
        </a>
      </ValueWithLabel>
    );
  } else {
    return null;
  }
}
