/* eslint-disable react-hooks/exhaustive-deps */

import { useApolloClient } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { createCorrectUser } from "../../../components/utils/userUtils";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import { UserRoles } from "../../../data-model/types/User";
import { INITIAL_DATA_QUERY } from "../queries";
import { InitialDataQuery } from "../../__generated__/graphql";
import {
  DatabaseLanguageEquivalents,
  parseOneLanguageFromAPI,
} from "../../../components/language/languagesUtil";
import { useTranslation } from "react-i18next";

export default function useFetchMe() {
  const [role, setRole] = useState<UserRoles | undefined>();
  const [data, setData] = useState<InitialDataQuery | undefined>(undefined);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const apolloClient = useApolloClient();

  const executeFetchMeQuery = useCallback(
    async (role: UserRoles) => {
      setLoadingData(true);
      setRole(role);

      /**
         * we stopped using lazyquery (as seen below) because of the way apollo hooks work: a query (even a lazy one)
         * will refetch data if the cache has been cleared, so logging out of the app causes the query to be executed again
         * and so the data will be loaded before we actually need it, resulting in this component not rerendering (because the state doesn't
         * change, as the data is already there)
         * const [executeQuery, { data, error, loading }] =
                useLazyQuery<APIMe.Fetch.Result>(INITIAL_DATA_QUERY, {
                    fetchPolicy: "no-cache",
                    onCompleted: (data) => console.log("Completed! data:", data),
                });
         */

      const result = await apolloClient.query({
        fetchPolicy: "no-cache",
        query: INITIAL_DATA_QUERY,
      });

      setData(result.data);
      setLoadingData(false);
    },
    [apolloClient],
  );

  const user = useMemo((): Human | Professional | undefined => {
    if (data?.currentUser && role) {
      const user = data.currentUser;

      i18n.changeLanguage(parseOneLanguageFromAPI(user.preferredLanguage));

      return createCorrectUser(
        {
          avatar: {
            url: user.professional?.avatar?.url ?? "",
          },
          canPayWithCredits: user.human?.canPayWithCredits,
          email: user.email,
          firstName: user.firstName ?? undefined,
          id: (user.human?.id ?? user.professional?.id) as number,
          lastName: user.lastName ?? undefined,
          onboardedAt: user.onboardedAt
            ? new Date(user.onboardedAt)
            : undefined,
          phone: user.phone ?? undefined,
          preferredLanguage:
            user.preferredLanguage as DatabaseLanguageEquivalents,
          timezone: user.timezone,
          uuid: user.id,
        },
        role,
      );
    }
  }, [data, role, loadingData]);

  return {
    fetchMe: executeFetchMeQuery,
    loading: loadingData,
    user,
  };
}
