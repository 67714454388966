import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";

//TODO: check if we are just tracking one page view or doing this multiple times (every state update?)
export default function useTrackPageView(
  pageTitle: string,
  customDimensions?: any[],
) {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    const track = () => {
      try {
        trackPageView({
          customDimensions,
          documentTitle: pageTitle,
        });
      } catch (error) {
        console.warn(`Could not track pageview with Matomo: ${error}`);
      }
    };

    track();
  }, [customDimensions, pageTitle, trackPageView]);
}
