import { useRef } from "react";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Theme } from "@mui/material";
import { UserRoles } from "../../../data-model/types/User";
import DeprecatedAvatar from "../../ui/DeprecatedAvatar";
import Professional from "../../../data-model/types/professional/Professional";
import Human from "../../../data-model/types/human/Human";
import UserMenu from "../../ui/UserMenu";
import AdminSidebarNavigation from "./AdminSidebarNavigation";
import HumanSidebarNavigation from "./HumanSidebarNavigation";
import ProfessionalSidebarNavigation from "./ProfessionalSidebarNavigation";

const PREFIX = "Sidebar";

const classes = {
  header: `${PREFIX}-header`,
  menuOpen: `${PREFIX}-menuOpen`,
  sidebar: `${PREFIX}-sidebar`,
  tabletAvatarUser: `${PREFIX}-tabletAvatarUser`,
  userButton: `${PREFIX}-userButton`,
  userName: `${PREFIX}-userName`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.sidebar}`]: {
    background: theme.palette.white.main,
    flexFlow: "column nowrap",
    height: "100vh",
    justifyContent: "flex-start",
    minHeight: "100vh",
    overflowY: "auto",
    zIndex: 2,

    [theme.breakpoints.down("sm")]: {
      flexFlow: "row nowrap",
      height: "auto",
      minHeight: "auto",
      overflowY: "visible",
      width: "100%",
    },

    [theme.breakpoints.between("sm", "lg")]: {
      overflowY: "visible",
      width: 115,
    },
  },

  [`& .${classes.header}`]: {
    alignItems: "center",
    display: "flex",
    flexFlow: "row nowrap",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.userButton}`]: {
    "&:hover": {
      background: theme.palette.bloom.dark,
    },
    background: theme.palette.bloom.dark,
    borderRadius: 0,
    color: theme.palette.white.main,
    fontSize: 20,

    fontWeight: 600,
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
    textTransform: "none",
    width: "100%",
  },

  [`& .${classes.menuOpen}`]: {
    background: theme.palette.bloom.dark,
    color: theme.palette.white.main,
  },

  [`& .${classes.userName}`]: {
    fontSize: 18,
    margin: 0,
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.between("sm", "lg")]: {
      paddingLeft: theme.spacing(1),
      visibility: "hidden",
    },
  },

  [`& .${classes.tabletAvatarUser}`]: {
    "& .MuiButton-endIcon": {
      left: 75,
      position: "absolute",
    },
  },
}));

interface ISideBar {
  currentUser: Professional | Human;
  isMenuOpen: boolean;
  logout: () => void;
  openMenu: (isOpen: boolean) => void;
}

export default function Sidebar({
  logout,
  isMenuOpen,
  openMenu,
  currentUser,
}: ISideBar) {
  const tablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "lg"),
  );

  const ref = useRef(null);
  const role = currentUser.getRole();

  return (
    <StyledBox className={classes.sidebar}>
      <header className={classes.header}>
        <Button
          className={`
                        ${isMenuOpen ? classes.menuOpen : ""}
                        ${tablet ? classes.tabletAvatarUser : ""}`}
          classes={{
            text: classes.userButton,
          }}
          endIcon={isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          id="test_sidebar_button_main_dropdown"
          onClick={(ev) => {
            ev.stopPropagation();
            openMenu(!isMenuOpen);
          }}
          ref={ref}
        >
          <Box alignItems="center" display="flex" flexDirection="row">
            <DeprecatedAvatar size={50} user={currentUser} />
            <Typography
              align="left"
              className={classes.userName}
              display="inline"
              noWrap
            >
              {currentUser.getFullName()}
            </Typography>
          </Box>
        </Button>
        <UserMenu
          buttonRef={ref}
          isMenuOpen={isMenuOpen}
          logout={logout}
          openMenu={openMenu}
        />
      </header>
      {role &&
        [UserRoles.ORGANIZATION_ADMIN, UserRoles.HUMAN].includes(role) && (
          <HumanSidebarNavigation />
        )}
      {role === UserRoles.PROFESSIONAL && <ProfessionalSidebarNavigation />}
      {role === UserRoles.ADMIN && <AdminSidebarNavigation />}
    </StyledBox>
  );
}
