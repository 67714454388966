import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import { Typography } from "@mui/material";

const professionalFragment = graphql(`
  fragment CostSentenceProfessional on Professional {
    id
    standardDuration
    standardRate
  }
`);

const organizationFragment = graphql(`
  fragment CostSentenceOrganization on Organization {
    canCreditsBeUsed {
      forCoaching
      forTherapy
    }
    coachingEnabled
    coachingsPerHuman
    consultsPerHuman
    contractEndDate
    id
    name {
      ...useSelectTranslationStringInput
    }
    slug
    videoEnabled
  }
`);

const humanFragment = graphql(`
  fragment CostSentenceHuman on Human {
    creditBalance {
      remainingCoachingConsultations
      remainingTherapyConsultations
    }
    id
  }
`);

const CostSentence = ({
  appointmentType,
  human,
  isMatching = false,
  mutualityName,
  organization,
  professional,
}: {
  appointmentType: "COACHING" | "THERAPY";
  human?: FragmentType<typeof humanFragment> | null;
  isMatching?: boolean;
  mutualityName?: string;
  organization?: FragmentType<typeof organizationFragment> | null;
  professional: FragmentType<typeof professionalFragment>;
}) => {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();
  const translationKeyPrefix = isMatching
    ? "match:chooseProfStep.card"
    : "human:request.consultation";

  const professionalData = getFragmentData(professionalFragment, professional);
  const organizationData = getFragmentData(organizationFragment, organization);
  const humanData = getFragmentData(humanFragment, human);
  const orgCanCreditsBeUsed =
    appointmentType === "THERAPY"
      ? organizationData?.canCreditsBeUsed?.forTherapy
      : organizationData?.canCreditsBeUsed?.forCoaching;

  // Same for Helan & CM
  const reimbursementPrice = professionalData.standardRate
    ? professionalData.standardRate - 10
    : undefined;

  const getSentence = () => {
    if (
      (organizationData?.videoEnabled === true &&
        (organizationData.consultsPerHuman ?? 0) > 0 &&
        appointmentType === "THERAPY") ||
      (organizationData?.coachingEnabled === true &&
        (organizationData.coachingsPerHuman ?? 0) > 0 &&
        appointmentType === "COACHING")
    ) {
      const orgName = selectTranslation(organizationData.name);

      if (organizationData.consultsPerHuman === null && orgCanCreditsBeUsed) {
        return t(`${translationKeyPrefix}.cost_org_unlimited`, { orgName });
      } else if (appointmentType === "COACHING") {
        // We don't take the amount of credits or the mutuality into account yet
        //  given we don't have a B2C option
        return t(`${translationKeyPrefix}.cost_org_limited_coaching`, {
          coachingsPerYear: organizationData.coachingsPerHuman,
          contractEnd: organizationData.contractEndDate,
          count:
            humanData?.creditBalance?.remainingCoachingConsultations ??
            organizationData.coachingsPerHuman ??
            undefined,
          duration: 30,
          orgName,
        });
      } else {
        const translationProps = {
          consultsPerYear: organizationData.consultsPerHuman,
          contractEnd: organizationData.contractEndDate,
          count:
            humanData?.creditBalance?.remainingTherapyConsultations ??
            organizationData.consultsPerHuman ??
            undefined,
          duration: professionalData.standardDuration,
          orgName,
          price: professionalData.standardRate,
          reimbursementPrice,
        };

        if (
          orgCanCreditsBeUsed &&
          (humanData?.creditBalance?.remainingTherapyConsultations ===
            undefined ||
            humanData.creditBalance.remainingTherapyConsultations === null || // unlimited case should have been covered above
            humanData.creditBalance.remainingTherapyConsultations > 0)
        ) {
          return mutualityName === "CM"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_consult_cm`,
                translationProps,
              )
            : mutualityName === "Helan"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_consult_helan`,
                translationProps,
              )
            : t(
                `${translationKeyPrefix}.cost_org_limited_consult`,
                translationProps,
              );
        } else {
          return mutualityName === "CM"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_consult_cm_limit_reached`,
                translationProps,
              )
            : mutualityName === "Helan"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_consult_helan_limit_reached`,
                translationProps,
              )
            : t(
                `${translationKeyPrefix}.cost_org_limited_consult_limit_reached`,
                translationProps,
              );
        }
      }
    } else {
      return mutualityName === "CM"
        ? t(`${translationKeyPrefix}.cost_cm`, {
            duration: professionalData.standardDuration,
            price: professionalData.standardRate,
            reimbursementPrice,
          })
        : mutualityName === "Helan"
        ? t(`${translationKeyPrefix}.cost_helan`, {
            duration: professionalData.standardDuration,
            price: professionalData.standardRate,
            reimbursementPrice,
          })
        : t(`${translationKeyPrefix}.cost`, {
            duration: professionalData.standardDuration,
            price: professionalData.standardRate,
          });
    }
  };

  return <Typography>{getSentence()}</Typography>;
};

export default CostSentence;
