import React, { useContext, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, List, Theme } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import AuthContext from "../../providers/auth/AuthContext";
import { useQuery } from "@apollo/client";
import LoadingPage from "../../layout/LoadingPage";
import RightSection from "../../layout/RightSection";
import LeftSection from "../../layout/LeftSection";
import ProfessionalListItem from "./ProfessionalListItem";
import UnavailableSection from "../../layout/UnavailableSection";
import ViewOnlyProfileInfo from "../../profile/ViewOnlyProfileInfo";
import ViewOnlyProfessionalProfile from "../professional-profile/ViewOnlyProfessionalProfile";

const professionalsForHumanOverviewQuery = graphql(`
  query ProfessionalsForHumanOverview(
    $input: ProfessionalsForCurrentHumanInput!
  ) {
    professionalsForCurrentHuman(input: $input) {
      edges {
        cursor
        node {
          id
          ...ProfessionalListItem
          user {
            ...ViewOnlyProfileInfo
          }
          ...ViewOnlyProfessionalProfile
        }
      }
      totalCount
    }
  }
`);

export default function ProfessionalsPage() {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const { currentUser } = useContext(AuthContext);
  const [selectedProf, setSelectedProf] = useState<number>();

  const { data, loading } = useQuery(professionalsForHumanOverviewQuery, {
    variables: { input: { withAvailability: false } },
  });

  const professionals = data?.professionalsForCurrentHuman?.edges
    ?.map((e) => e?.node)
    .filter((prof) => prof !== null && prof !== undefined);

  const currentProf = professionals?.find((p) => p.id === selectedProf);

  if (loading || !currentUser || professionals === undefined)
    return <LoadingPage />;

  return (
    <>
      {!(small && currentProf) && (
        <LeftSection pageTitle={t("common:pros")} short={false}>
          {professionals.length > 0 ? (
            <List>
              {professionals.map((professional) => (
                <ProfessionalListItem
                  key={professional.id}
                  professional={professional}
                  selectedProfId={selectedProf}
                  setSelected={(id: number) => setSelectedProf(id)}
                />
              ))}
            </List>
          ) : (
            <UnavailableSection text={t("client:professionals.none")} />
          )}
        </LeftSection>
      )}
      {currentProf && (
        <RightSection
          handleClose={() => {
            setSelectedProf(undefined);
          }}
          title={t("client:pros.pro.title")}
        >
          <Box>
            <ViewOnlyProfileInfo user={currentProf.user} />
            <ViewOnlyProfessionalProfile professional={currentProf} />
          </Box>
        </RightSection>
      )}
    </>
  );
}
