import { graphql } from "../__generated__";

export const MUTATION_PROFESSIONAL_UPDATE = graphql(`
  mutation updateProfessional($input: ProfessionalInput!) {
    updateProfessional(input: $input) {
      ...ProfessionalFull
    }
  }
`);

export const MUTATION_PROFESSIONAL_VERIFY = graphql(`
  mutation verifyProfessional($id: Int!) {
    verifyProfessional(id: $id) {
      id
    }
  }
`);
