import { styled } from "@mui/material/styles";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useTranslation } from "react-i18next";
import { RouteNames } from "../../routes/routeNames";
import { graphql } from "../../../api/__generated__";
import { useQuery } from "@apollo/client";
import environment from "../../../environment/environment";
import SchoolIcon from "@mui/icons-material/School";
import { useContext } from "react";
import AuthContext from "../../providers/auth/AuthContext";
import SidebarNavigationItem from "./SidebarNavigationItem";

const humanSidebarQuery = graphql(`
  query HumanSidebar {
    currentUser {
      human {
        id
        organization {
          id
          mindlabEnabled
        }
      }
      id
    }
  }
`);

const PREFIX = "HumanSidebarNavigation";

const classes = {
  actions: `${PREFIX}-actions`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.actions}`]: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(1),
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      background: theme.palette.white.main,
      bottom: 0,
      boxShadow: "0 -1px 7px 0 rgba(181,181,181,0.50)",
      left: 0,
      padding: 0,
      position: "fixed",
      right: 0,
      width: "100%",
      zIndex: 2,
    },
  },

  [`& .${classes.list}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row nowrap",
      margin: 0,
    },
  },
}));

export default function HumanSidebarNavigation() {
  const { t } = useTranslation();
  const { data } = useQuery(humanSidebarQuery);
  const { currentUser } = useContext(AuthContext);
  const role = currentUser?.getRole();

  return (
    <Root className={classes.actions}>
      <ul className={classes.list}>
        {role === "ORGANIZATION_ADMIN" && (
          <SidebarNavigationItem
            href={RouteNames.Home.OrganizationUsage.path}
            icon={<EqualizerIcon />}
            id={"test_sidebar_button_professionals"}
            label={t("ui:menu.organization.usage", "Gebruik")}
          />
        )}
        <SidebarNavigationItem
          href="/match"
          icon={<AllInclusiveIcon />}
          id={"test_sidebar_button_start_matching"}
          label={t("ui:menu.startmatching")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Consultations.path}
          icon={<TodayOutlinedIcon />}
          id={"test_sidebar_button_consultations"}
          label={t("ui:menu.consultations")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Professionals.path}
          icon={<FaceOutlinedIcon />}
          id={"test_sidebar_button_professionals"}
          label={t("ui:menu.professionals")}
        />
        {data?.currentUser?.human?.organization?.mindlabEnabled && (
          <SidebarNavigationItem
            href={`${environment.MINDLAB_FRONTEND_URL}`}
            icon={<SchoolIcon />}
            id={"test_sidebar_button_start_matching"}
            label={t("ui:menu.learn")}
            target="_blank"
          />
        )}
      </ul>
    </Root>
  );
}
