import Grid from "@mui/material/Grid";
import { lighten, Theme, styled } from "@mui/material/styles";
import { useLayoutEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import StatusIcon from "../StatusIcon";
import { IWithChildren } from "../../../baseInterfaces";
import { UserRoles } from "../../../data-model/types/User";
import { HumanStatus } from "../../../data-model/types/human/Human";
import {
  ConsultationStatus,
  RequestedByType,
} from "../../../api/__generated__/graphql";

const PREFIX = "ButtonWithStatus";

const classes = {
  buttonFrame: `${PREFIX}-buttonFrame`,
};

const Root = styled("div")<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  [`&.${classes.buttonFrame}`]: {
    "& .open": {
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.white.main} !important`,
      },
    },
    "&:focus": {
      outline: 0,
    },
    "&:hover": {
      backgroundColor: isOpen
        ? lighten(theme.palette.green.main, 0.7)
        : lighten(theme.palette.bloom.main, 0.5),
    },
    "&:last-child": {
      marginBottom: 0,
    },
    alignItems: "center",
    background: isOpen
      ? lighten(theme.palette.green.main, 0.7)
      : theme.palette.white.main,
    border: "none",
    borderLeft: "solid 3px",
    borderLeftColor: isOpen
      ? theme.palette.green.main
      : theme.palette.bloom.main,
    cursor: "pointer",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },

    padding: theme.spacing(3),

    position: "relative",

    transition: "background-color 0.15s ease-in-out",

    width: "100%",
  },
}));

interface IButtonWithStatus extends IWithChildren {
  isOpen: boolean;
  onClick: () => void;
  requestedBy: RequestedByType;
  roleType?: UserRoles;
  status: ConsultationStatus | HumanStatus | undefined;
  style?: React.CSSProperties;
}

const ButtonWithStatus = ({
  onClick,
  isOpen,
  status,
  roleType,
  style,
  children,
  requestedBy,
}: IButtonWithStatus) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down(470));

  useLayoutEffect(() => {
    if (isOpen && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isOpen, buttonRef]);

  return (
    <Root
      className={classes.buttonFrame}
      isOpen={isOpen}
      onClick={onClick}
      ref={buttonRef}
      style={style}
    >
      <Grid className={isOpen ? "open" : "notopen"} container spacing={1}>
        <Grid item xs={status ? (small ? 10 : 11) : 12}>
          {children}
        </Grid>
        {status && (
          <Grid item xs={1}>
            <StatusIcon
              isProfessional={roleType === UserRoles.PROFESSIONAL}
              requestedBy={requestedBy}
              status={status}
            />
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

export default ButtonWithStatus;
