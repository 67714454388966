/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@mui/material/Box";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import InlineEditableAddress from "../../ui/edit/InlineEditableAddress";
import InlineEditableDate from "../../ui/edit/InlineEditableDate";
import InlineEditableField from "../../ui/edit/InlineEditableField";
import InlineEditableMultiSelect from "../../ui/edit/InlineEditableMultiSelect";
import InlineEditableProfessionalOther from "../../ui/edit/InlineEditableProfessionalOther";
import InlineEditableRate from "../../ui/edit/InlineEditableRate";
import { APIAddress } from "../../../api/addresses/addresses";
import CMNumber from "../../../data-model/types/professional/CMNumber";
import ConsultationLanguages from "../../../data-model/types/professional/ConsultationLanguages";
import Experience from "../../../data-model/types/professional/Experience";
import Therapies from "../../../data-model/types/professional/Therapies";
import VisumNumber from "../../../data-model/types/professional/VisumNumber";
import Professional from "../../../data-model/types/professional/Professional";
import EditableDescriptionsMultipleLanguages from "./EditableDescriptionsMultipleLanguages";
import { graphql } from "../../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import SubtopicSelect from "./components/SubtopicSelect";
import BloomUpSwitch from "../../ui/BloomUpSwitch";
import { Typography } from "@mui/material";

const queryProfessionalProfile = graphql(`
  query ProfessionalProfile {
    currentUser {
      id
      professional {
        coachingEnabled
        id
        ...SubtopicsOnProfessional
        therapyEnabled
      }
    }
  }
`);

const toggleCoachingEnabledForProfessionalMutation = graphql(`
  mutation ToggleCoachingEnabledForProfessional($input: Boolean!) {
    toggleCoachingEnabledForProfessional(input: $input) {
      coachingEnabled
      id
    }
  }
`);

const toggleTherapyEnabledForProfessionalMutation = graphql(`
  mutation ToggleTherapyEnabledForProfessional($input: Boolean!) {
    toggleTherapyEnabledForProfessional(input: $input) {
      id
      therapyEnabled
    }
  }
`);

interface ProfessionalProfile {
  formState?: any;
  onSave: () => Promise<void>;
  professional: Professional;
  //TODO: change this any
  updateProfessionalDescriptions: (input: any) => Promise<void>;
  updateProfessionalPractice: (address: APIAddress.Input) => Promise<void>;
}

export default function EditableProfessionalProfile({
  professional,
  formState,
  onSave,
  updateProfessionalPractice,
  updateProfessionalDescriptions,
}: ProfessionalProfile) {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Ui,
  ]);

  const { data } = useQuery(queryProfessionalProfile);
  const gqlProfessional = data?.currentUser?.professional;
  const [toggleTherapyEnabledForProfessional] = useMutation(
    toggleTherapyEnabledForProfessionalMutation,
  );

  const [toggleCoachingEnabledForProfessional] = useMutation(
    toggleCoachingEnabledForProfessionalMutation,
  );

  return (
    <Box pb={5}>
      <Box>
        <EditableDescriptionsMultipleLanguages
          onSave={updateProfessionalDescriptions}
          professional={professional}
        />
      </Box>
      {gqlProfessional ? (
        <>
          <Box mt={3}>
            <SubtopicSelect
              label={t("profile:primary_subtopics_label")}
              primary={true}
              professional={gqlProfessional}
            />
          </Box>
          <Box mt={3}>
            <SubtopicSelect
              label={t("profile:secondary_subtopics_label")}
              primary={false}
              professional={gqlProfessional}
            />
          </Box>
          <Box sx={{ marginTop: "12px" }}>
            <Typography
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.gray.light}`,
                color: theme.palette.bloom.dark,
                fontSize: theme.typography.sizes.normal,
                fontWeight: 600,
                paddingBottom: "8px",
              })}
            >
              {t("profile:coaching_therapy.title")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <BloomUpSwitch
                label={t("profile:coaching_enabled_switch.label")}
                onChange={async (value) => {
                  await toggleCoachingEnabledForProfessional({
                    variables: { input: value },
                  });
                }}
                toolTip={t("profile:coaching_enabled_switch.tooltip")}
                value={gqlProfessional.coachingEnabled}
              />
              <BloomUpSwitch
                disabled={true}
                label={t("profile:therapy_enabled_switch.label")}
                onChange={async (value) => {
                  await toggleTherapyEnabledForProfessional({
                    variables: { input: value },
                  });
                }}
                toolTip={t("profile:therapy_enabled_switch.tooltip")}
                value={gqlProfessional.therapyEnabled}
              />
            </Box>
          </Box>
        </>
      ) : undefined}
      <Box mt={3}>
        <InlineEditableMultiSelect<Therapies>
          dataObject={professional.getTherapies()}
          formState={formState}
          label={t("professional:profile.therapytypes")}
          onSave={onSave}
          placeholderText={t("professional:onboarding.therapytype.placeholder")}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableMultiSelect<ConsultationLanguages>
          cancelBtnTestId="test_profile_consultation_language_select_cancel"
          chipListTestId="test_profile_consultation_language_chiplist"
          dataObject={professional.getConsultationLanguages()}
          editBtnTestId="test_profile_consultation_language_select_edit"
          formState={formState}
          label={t("professional:profile.consultation.languages")}
          labelTestId="test_profile_consultation_language_label"
          onSave={onSave}
          placeholderText={t(
            "professional:profile.consultation.languages.label",
          )}
          saveBtnTestId="test_profile_consultation_language_select_save"
          selectTestId="test_profile_consultation_language_select"
        />
      </Box>
      <Box mt={3}>
        <InlineEditableRate
          durationDataObject={professional.getConsultationDuration()}
          formState={formState}
          label={t("professional:profile.rate")}
          onSave={onSave}
          rateDataObject={professional.getConsultationRate()}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableDate<Experience>
          dataObject={professional.getExperience()}
          formState={formState}
          format="MM/YYYY"
          fullWidth
          label={t("common:experience.label")}
          maxDate={moment()}
          minDate={moment().subtract(100, "year")}
          onSave={onSave}
          openTo="year"
          placeholder={t("ui:experience.placeholder")}
          views={["year", "month"]}
        />
      </Box>

      <Box mt={3}>
        <InlineEditableField<CMNumber>
          dataObject={professional.getCMNumber()}
          formState={formState}
          label={t("ui:cmnumber.optional")}
          onSave={onSave}
          placeholder={t(
            "professional:onboarding.visumandcmnumber.cm.placeholder",
          )}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableField<VisumNumber>
          dataObject={professional.getVisumNumber()}
          formState={formState}
          label={t("ui:visumnumber.optional")}
          onSave={onSave}
          placeholder={t(
            "professional:onboarding.visumandcmnumber.visumnumber.placeholder",
          )}
        />
      </Box>

      <Box mt={3}>
        <InlineEditableAddress
          formState={formState}
          initialAddress={professional.getPractice().getAddress()}
          label={t("professional:profile.proaddress")}
          onSave={updateProfessionalPractice}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableField
          dataObject={professional.getWebsite()}
          formState={formState}
          label={t("ui:website.optional")}
          onSave={onSave}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableProfessionalOther
          formState={formState}
          label={t("professional:profile.other")}
          onSave={onSave}
          professional={professional}
        />
      </Box>
    </Box>
  );
}
