import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MultiSelect from "../../../ui/form/select/MultiSelect";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import Label from "../../../ui/form/FormLabel";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";
import ConsultationLanguages from "../../../../data-model/types/professional/ConsultationLanguages";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

export default function ConsultationLanguagesStep() {
  const { next, previous, step, formState, totalSteps } =
    useContext<ProfessionalOnboardingContextProps>(
      ProfessionalOnboardingContext,
    );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Ui,
  ]);

  const error = formState.testForErrors(ConsultationLanguages.getPath());

  const selectDefaultValue = () => {
    const selectedItems = new ConsultationLanguages();

    selectedItems.setValue(formState.getValue(ConsultationLanguages.getPath()));
    const selected = selectedItems.getSelectedOptions(translate);

    return selected;
  };

  const selectOptions = useMemo(
    () => new ConsultationLanguages().getSelectOptions(translate),
    [translate],
  );

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={next}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.languages.title",
          "Je talen",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Label
          label={translate(
            "professional:onboarding.languages.description",
            "Duid de talen aan waarin je therapie kan geven.",
          )}
        />
        <MultiSelect
          closeMenuOnSelect
          options={selectOptions}
          placeholder={translate(
            "professional:onboarding.languages.label",
            "Selecteer een taal...",
          )}
          {...formState.getInputProps(ConsultationLanguages.getPath())}
          defaultValue={selectDefaultValue()}
          selectTestId={`test_onboarding_professional_consultation_language_select`}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
