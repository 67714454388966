import { createContext } from "react";
import { ProfessionalOnboardingContextProps } from "./professionalOnboarding";

const defaultProfessionalOnboardingValues: ProfessionalOnboardingContextProps =
  {
    disable: false,
    error: undefined,
    formState: {},
    next: () => {},
    previous: () => {},
    setStep: () => {},
    step: 0,
    totalSteps: 0,
  };

const ProfessionalOnboardingContext =
  createContext<ProfessionalOnboardingContextProps>(
    defaultProfessionalOnboardingValues,
  );

export default ProfessionalOnboardingContext;
