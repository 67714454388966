import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import BloomCirlcle from "./BloomCircle.svg";
import BloomCirlcleFilled from "./BloomCircleFilled.svg";
import BloomCheckCircle from "./BloomCheckCircle.svg";
import BloomCheckMark from "./BloomCheckMark.svg";
import BloomOutline from "./BloomCircleOutline.svg";
import BloomOutlineHover from "./BloomCircleOutlineGreen.svg";

interface BloomieCardProps {
  bodyChildren?: React.ReactNode;
  bodyText?: string;
  checked?: boolean;
  footerChildren?: React.ReactNode;
  imgUrl: string;
  logoImg?: boolean;
  onClick?: () => void;
  size?: "md" | "lg" | "xl";
  subTitle?: string;
  subTitleColor?: string;
  sx?: SxProps;
  testId?: string;
  title?: string;
  type: "blank" | "outlined" | "filled";
}

const BloomieCard = ({
  bodyText,
  size = "md",
  imgUrl,
  title,
  type,
  onClick,
  checked = false,
  logoImg,
  bodyChildren,
  footerChildren,
  subTitle,
  subTitleColor,
  sx,
  testId,
}: BloomieCardProps) => {
  const theme = useTheme();

  return (
    <Card
      data-testId={testId}
      sx={{
        "&:hover .bodyText": {
          color: onClick
            ? checked
              ? undefined
              : theme.palette.black.main
            : undefined,
          transition: "color 0.5s ease-in-out",
        },
        "&:hover .outline": {
          backgroundImage: onClick
            ? checked
              ? undefined
              : `url(${BloomOutlineHover})`
            : undefined,
          transition: "background-image 0.5s ease-in-out",
        },
        "&:hover .titleText": {
          color: onClick
            ? checked
              ? undefined
              : theme.palette.green.dark
            : undefined,
          transition: "colo 0.5s ease-in-out",
        },
        ".MuiCardActionArea-focusHighlight": {
          background: "transparent",
        },
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        maxWidth: size === "md" ? "200px" : "275px",
        overflow: "visible",
        textAlign: "center",
        [theme.breakpoints.down(450)]: {
          maxWidth: "80%",
        },
        ...sx,
      }}
    >
      <CardActionArea
        disableRipple
        onClick={() => {
          if (onClick) onClick();
        }}
        sx={{
          cursor: onClick ? "pointer" : "default",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box>
          {checked && (
            <Box
              sx={{
                left: size === "md" ? "77px" : "110px",
                position: "relative",
                top: size === "md" ? "142px" : "210px",
              }}
            >
              <Box
                component="img"
                src={BloomCheckCircle}
                sx={{
                  height: size === "md" ? undefined : "48px",
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: size === "md" ? undefined : "55px",
                }}
              />
              <Box
                component="img"
                src={BloomCheckMark}
                sx={{
                  height: size === "md" ? "20px" : "25px",
                  left: size === "md" ? 11 : 14.5, //prop needs to be specific for the check to be at the right position
                  position: "absolute",
                  top: size === "md" ? 11 : 14.5, //prop needs to be specific for the check to be at the right position
                  width: size === "md" ? "20px" : "25px",
                }}
              />
            </Box>
          )}

          <CardMedia
            className="outline"
            sx={{
              alignItems: "center",
              backgroundImage: `url(${
                checked || type === "filled"
                  ? BloomCirlcleFilled
                  : type === "outlined"
                  ? BloomOutline
                  : BloomCirlcle
              })`,
              backgroundRepeat: "no-repeat",
              display: "flex",
              height: size === "md" ? "170px" : "245px",
              justifyContent: "center",
              marginBottom: "9px",
              transition: "background-image 0.3s ease-out",
              width: size === "md" ? "190px" : "275px",
              [theme.breakpoints.down(350)]: {
                height: "160px",
                maxWidth: "180px",
              },
            }}
          >
            <Box
              component="img"
              draggable="false"
              src={imgUrl}
              sx={{
                maxHeight: logoImg
                  ? "55%"
                  : size === "lg" || size === "xl"
                  ? "250px"
                  : "120px",
                maxWidth: logoImg
                  ? "55%"
                  : size === "lg" || size === "xl"
                  ? "250px"
                  : "180px",

                minHeight: size === "md" ? undefined : "80px",
                minWidth: size === "md" ? undefined : "80px",
              }}
            />
          </CardMedia>
        </Box>

        {(bodyChildren || bodyText || title || subTitle) && (
          <CardContent
            sx={{
              padding: onclick === undefined ? 0 : undefined,
            }}
          >
            {subTitle && (
              <Typography
                sx={{
                  color: subTitleColor ?? undefined,
                  fontWeight: "bold",
                }}
              >
                {subTitle}
              </Typography>
            )}
            {title && (
              <Typography
                className="titleText"
                sx={{
                  fontSize:
                    size === "xl" ? "28px" : size === "lg" ? "22px" : undefined,
                  paddingY: "0.3rem",
                  transition: "color 0.3s ease-out",
                  [theme.breakpoints.down(450)]: {
                    fontSize: size === "lg" || size === "xl" ? "18px" : "16px",
                  },
                }}
                variant="h4"
              >
                {title}
              </Typography>
            )}
            {bodyText && (
              <Typography
                className="bodyText"
                sx={{
                  "&:hover": {
                    color: onClick
                      ? checked
                        ? undefined
                        : theme.palette.black.main
                      : undefined,
                  },
                  color: onClick
                    ? checked
                      ? theme.palette.black.main
                      : theme.palette.black.light
                    : "#797272",
                  fontSize: size === "lg" || size === "xl" ? "14px" : "12px",
                  fontWeight: 400,
                  marginBottom: "1.5rem",
                  transition: "color 0.3s ease-out",
                  whiteSpace: "pre-line",
                }}
                variant="body1"
              >
                {bodyText}
              </Typography>
            )}
            {bodyChildren && bodyChildren}
          </CardContent>
        )}
      </CardActionArea>
      {footerChildren && footerChildren}
    </Card>
  );
};

export default BloomieCard;
