export const START_STEP = 0;

export const BIRTH_DATE_STEP = START_STEP + 1;

export const GENDER_STEP = BIRTH_DATE_STEP + 1;

export const AVATAR_STEP = GENDER_STEP + 1;

export const TOPICS_STEP = AVATAR_STEP + 1;

export const THERAPY_STEP = TOPICS_STEP + 1;

export const CONSULTATION_LANGUAGES_STEP = THERAPY_STEP + 1;

export const PROFILE_STEP = CONSULTATION_LANGUAGES_STEP + 1;

export const EXPERIENCE_STEP = PROFILE_STEP + 1;

export const VISUM_AND_CM_STEP = EXPERIENCE_STEP + 1;

export const PRACTICE_ADDRESS_STEP = VISUM_AND_CM_STEP + 1;

export const RATE_STEP = PRACTICE_ADDRESS_STEP + 1;

export const BILLING_STEP = RATE_STEP + 1;

export const CONSENT_STEP = BILLING_STEP + 1;

export const SUCCESS_STEP = CONSENT_STEP + 1;

export const TOTAL_STEPS = 15;
