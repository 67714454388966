/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchOneProfessional from "../../../../api/professionals/hooks/useFetchOneProfessional";
import useUpdateProfessionalMutation from "../../../../api/professionals/hooks/useUpdateProfessionalMutation";
import { QUERY_FETCH_FULL_PROFESSIONAL_PROFILE } from "../../../../api/professionals/queries";
import AccountNumber from "../../../../data-model/types/billing/AccountNumber";
import BillingAddress from "../../../../data-model/types/billing/BillingAddress";
import LoadingPage from "../../../layout/LoadingPage";
import useUpdateUser from "../../../profile/hooks/useUpdateUser";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import {
  prepareLanguageInputForAPI,
  prepareOneLanguageForAPI,
} from "../../../language/languagesUtil";
import { IToastContext } from "../../../providers/toast/toast";
import ToastContext from "../../../providers/toast/ToastContext";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import { IDescriptionsValues } from "../EditableDescriptionsMultipleLanguages";
import useProfessionalProfileFormState from "../hooks/useProfessionalProfileFormState";
import { APIAddress } from "../../../../api/addresses/addresses";
import { APIBillingInformation } from "../../../../api/billingInformation/billingInformation";
import { APIProfessional } from "../../../../api/professionals/professionals";
import Address from "../../../../data-model/types/profile/Address";
import { ProfessionalInput } from "../../../../api/__generated__/graphql";
import ProfessionalProfileContext from "./ProfessionalProfileContext";

export default function ProfessionalProfileContextProvider({ children }) {
  const { setToast } = useContext<IToastContext>(ToastContext);
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Errors,
  ]);

  const { updateTimezone, updatePreferredLanguage } =
    useUpdateUser("professional");

  const formState = useProfessionalProfileFormState();

  const { updateProfessional, loading: updatingProfessional } =
    useUpdateProfessionalMutation();

  const { loading, professional } = useFetchOneProfessional(
    QUERY_FETCH_FULL_PROFESSIONAL_PROFILE,
  );

  useEffect(() => {
    if (!loading && professional) {
      formState.setValues(professional.getAsFormStateValues(translate));
    }
  }, [loading, professional]);

  const getAvatarValue = (avatar) => {
    if (!avatar) return null;
    else return "preview" in avatar ? avatar : null;
  };

  const getProfileValues = (formValues: IDescriptionsValues) => {
    const descriptionsObject = {};

    professional
      ?.getConsultationLanguages()
      .getValue()
      .forEach((language: string) => {
        const key = prepareOneLanguageForAPI(language);
        const descriptionInFormState = formValues[key];

        if (descriptionInFormState) {
          descriptionsObject[key] = descriptionInFormState;
        }
      });

    return descriptionsObject;
  };

  const removeKeysIncludedInProfile = (input) => {
    Object.keys(formState.values).forEach((key: string) => {
      if (key.includes("description")) delete input[key];
    });
  };

  const isArrayOfSelectObjects = (
    toBeDetermined: Array<string> | Array<ISelectObject>,
  ): toBeDetermined is Array<ISelectObject> => {
    if (toBeDetermined && toBeDetermined[0]) {
      if ((toBeDetermined[0] as ISelectObject).value) return true;
    }

    return false;
  };

  //TODO: We should not have to do this. Rethink how we are retrieving and storing this.
  const parseConsultationLanguages = (
    consultationLanguages: Array<string> | Array<ISelectObject>,
  ) => {
    const arrayOfLanguages: Array<string> = mapOrReturn(consultationLanguages);

    return prepareLanguageInputForAPI(arrayOfLanguages);
  };

  const mapOrReturn = (toBeMapped: Array<string> | Array<ISelectObject>) => {
    return isArrayOfSelectObjects(toBeMapped)
      ? toBeMapped.map((l: ISelectObject) => l.value)
      : toBeMapped;
  };

  const updateDescriptions = async (values: IDescriptionsValues) => {
    const input: Partial<APIProfessional.Update.Input> = {
      profile: JSON.stringify(getProfileValues(values)),
    };

    return updateProfessional(input);
  };

  const updatePractice = async (address: APIAddress.Input) => {
    if (address.postcode && address.street) {
      const input = {
        practice: {
          address: {
            country: "BE",
            postcode: address.postcode,
            street: address.street,
          },
        },
      };

      return updateProfessionalFn(input);
    }
  };

  const updateBillingInformation = (
    billingInformation: APIBillingInformation.Output,
  ) => {
    const input: { billingInformation: APIBillingInformation.Output } = {
      billingInformation: {
        accountNumber: billingInformation.accountNumber,
        address: {
          country: "BE",
          postcode: billingInformation.address.postcode ?? "",
          street: billingInformation.address.street ?? "",
        },
      },
    };

    return updateProfessionalFn(input);
  };

  const updateProfessionalFn = async (data: ProfessionalInput) => {
    try {
      await updateProfessional(data);
    } catch (e) {
      console.error(e);
      setToast({
        message: translate("errors:update.profile.professional"),
        severity: "warning",
      });
    }
  };

  const onSave = async () => {
    const {
      avatar,
      address,
      consultationLanguages,
      firstName,
      gender,
      lastName,
      phone,
      therapyTypes,
      supervision,
      isFirstLine,
      birthDate,
      billingAddress,
      visumNumber,
      cmNumber,
      standardRate,
      experienceSince,
      standardDuration,
      accountNumber,
      email,
      website,
    } = formState.values;

    if (
      billingAddress &&
      (!billingAddress.street || !billingAddress.postcode)
    ) {
      setToast({
        message: "Gelieve een volledig facturatie adres in te geven.",
        severity: "warning",
      });

      return;
    }

    const input = {
      avatar: getAvatarValue(avatar),
      billingInformation: {
        accountNumber,
        address: {
          country: "BE",
          postcode: billingAddress.postcode ?? "",
          street: billingAddress.street ?? "",
        },
      },
      birthDate: moment(birthDate).toDate(),

      cmNumber,

      consultationLanguages: JSON.stringify(
        parseConsultationLanguages(consultationLanguages),
      ),

      email,

      experienceSince,

      firstName,

      gender,

      isFirstLine,

      lastName,

      phone,

      //profile: JSON.stringify(getProfileValues()),
      practice: {
        address: {
          country: "BE",
          postcode: address.postcode ?? "",
          street: address.street ?? "",
        },
      },

      standardDuration,
      standardRate,
      supervision,
      therapyTypes: JSON.stringify(mapOrReturn(therapyTypes)),
      visumNumber,
      website,
    };

    delete input[Address.getPath()];
    delete input[BillingAddress.getPath()];
    delete input[AccountNumber.getPath()];

    removeKeysIncludedInProfile(input);

    // Delete fields that have empty values
    if (!input.avatar) delete input.avatar;

    try {
      await updateProfessional(input);
    } catch (e) {
      setToast({
        message:
          "There was an error updating your profile. Please check your answers and try again.",
        severity: "warning",
      });
      console.log(e);
    }
  };

  if (loading || updatingProfessional) return <LoadingPage full={false} />;

  return (
    <ProfessionalProfileContext.Provider
      value={{
        formState,
        loading,
        onSave,
        professional,
        updateBillingInformation,
        updateDescriptions,
        updatePractice,
        updatePreferredLanguage,
        updateTimezone,
      }}
    >
      {children}
    </ProfessionalProfileContext.Provider>
  );
}
