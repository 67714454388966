import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import ChipList from "../../ui/form/ChipList";
import GroupedChipList from "../../ui/form/GroupedChipList";
import Label from "../../ui/text/Label";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { useTheme } from "@mui/material";

const viewOnlyProfessionalProfileFragment = graphql(`
  fragment ViewOnlyProfessionalProfile on Professional {
    consultationLanguages
    experienceSince
    id
    practice {
      address {
        id
        postcode
        street
      }
      id
    }
    primarySubtopics {
      descriptionTranslationKey
      id
      titleTranslationKey
    }
    profileDescription {
      ...useSelectTranslationStringInput
    }
    secondarySubtopics {
      descriptionTranslationKey
      id
      titleTranslationKey
    }
    standardDuration
    standardRate
    therapyTypes
    website
  }
`);

interface ProfessionalProfileForHumanProps {
  professional: FragmentType<typeof viewOnlyProfessionalProfileFragment>;
}

export default function ViewOnlyProfessionalProfile({
  professional,
}: ProfessionalProfileForHumanProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const viewOnlyProfessionalData = getFragmentData(
    viewOnlyProfessionalProfileFragment,
    professional,
  );

  const selectTranslation = useSelectTranslation();
  const description = selectTranslation(
    viewOnlyProfessionalData.profileDescription,
  );

  const therapies: string[] = viewOnlyProfessionalData.therapyTypes
    ? JSON.parse(viewOnlyProfessionalData.therapyTypes).map((therapyType) =>
        t(`glossary:therapytype.${therapyType}`),
      )
    : [];

  const consultationLanguages =
    viewOnlyProfessionalData.consultationLanguages.reduce(
      (acc, consultationLanguage) => {
        if (consultationLanguage) {
          acc.push(t(`ui:languages.${consultationLanguage}`));
        }

        return acc;
      },
      [] as string[],
    );

  const address = viewOnlyProfessionalData.practice?.address;
  const addressString = `${address?.street} ${address?.postcode}`;

  return (
    <Box pb={5}>
      {description && (
        <Box mt={3}>
          <ValueWithLabel label={t(`professional:profile.aboutme`)}>
            {description}
          </ValueWithLabel>
        </Box>
      )}
      <Box mt={3}>
        <Label value={t("professional:primary.subtopics")} />
        <GroupedChipList
          chips={viewOnlyProfessionalData.primarySubtopics.map((topic) => {
            return {
              checkMark: false,
              color: theme.palette.gray.dark,
              fadeOut: false,
              label: t(topic.titleTranslationKey),
              tooltip: t(topic.descriptionTranslationKey),
            };
          })}
        />
        <Label value={t("professional:secondary.subtopics")} />
        <GroupedChipList
          chips={viewOnlyProfessionalData.secondarySubtopics.map((topic) => {
            return {
              checkMark: false,
              color: theme.palette.gray.dark,
              fadeOut: false,
              label: t(topic.titleTranslationKey),
              tooltip: t(topic.descriptionTranslationKey),
            };
          })}
        />
      </Box>
      <Box mb={3} mt={3}>
        <Label value={t("professional:profile.therapytypes")} />
        <ChipList list={therapies} />
      </Box>
      <Box mt={3}>
        <Label value={t("professional:profile.consultation.languages")} />
        <ChipList list={consultationLanguages} />
      </Box>
      <Box mt={3}>
        <ValueWithLabel label={t("professional:profile.rate")}>
          {t("human:tarrif.for.human", {
            duration: viewOnlyProfessionalData.standardDuration,
            rate: viewOnlyProfessionalData.standardRate,
          })}
        </ValueWithLabel>
      </Box>
      <Box mt={3}>
        <ValueWithLabel label={t("common:experience.label")}>
          {viewOnlyProfessionalData.experienceSince &&
            parseISO(viewOnlyProfessionalData.experienceSince).getFullYear()}
        </ValueWithLabel>
      </Box>
      <Box mt={3}>
        <ValueWithLabel label={t("professional:profile.proaddress")}>
          {addressString.length > 1 ? addressString : t("ui:address.not.found")}
        </ValueWithLabel>
      </Box>
      <Box mt={3}>
        <ValueWithLabel label={t("ui:website")}>
          {viewOnlyProfessionalData.website ?? t("ui:website.not.found")}
        </ValueWithLabel>
      </Box>
    </Box>
  );
}
